import React, { useEffect, useRef } from "react";
import gsap from "gsap";

const CanvasAnimation = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        let width = (canvas.width = window.innerWidth);
        let height = (canvas.height = window.innerHeight);
        let points = [];

        //  1. NUQTALAR (dots) YARATISH
        for (let x = 0; x < width; x += width / 20) {
            for (let y = 0; y < height; y += height / 20) {
                let px = x + Math.random() * (width / 20);
                let py = y + Math.random() * (height / 20);
                points.push({
                    x: px,
                    y: py,
                    originX: px,
                    originY: py,
                    closest: []
                });
            }
        }

        //  2. ENG YAQIN 5 NUQTANI TOPISH
        points.forEach((p1) => {
            let closest = [];
            points.forEach((p2) => {
                if (p1 !== p2) {
                    if (closest.length < 5) {
                        closest.push(p2);
                    } else {
                        closest.sort((a, b) => getDistance(p1, a) - getDistance(p1, b));
                        if (getDistance(p1, p2) < getDistance(p1, closest[4])) {
                            closest[4] = p2;
                        }
                    }
                }
            });
            p1.closest = closest;
        });

        //  3. NUQTALAR HARAKATLANISHI
        points.forEach((p) => {
            shiftPoint(p);
        });

        //  4. ANIMATSIYA
        const animate = () => {
            ctx.clearRect(0, 0, width, height);
            points.forEach((p) => {
                drawLines(ctx, p);
                drawCircle(ctx, p);
            });
            requestAnimationFrame(animate);
        };

        animate();

        //  5. EKRAN O‘ZGARGANDA YANGILASH
        const handleResize = () => {
            width = canvas.width = window.innerWidth;
            height = canvas.height = window.innerHeight;
        };
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return <canvas ref={canvasRef} id="bg-animation"></canvas>;
};

// NUQTALAR ORASIDAGI CHIZIQLARNI CHIZISH
const drawLines = (ctx, p) => {
    p.closest.forEach((closeP) => {
        ctx.beginPath();
        ctx.moveTo(p.x, p.y);
        ctx.lineTo(closeP.x, closeP.y);
        ctx.strokeStyle = "rgba(27, 107, 80, 1)"; // #1b6b50 rangida chiziqlar
        ctx.lineWidth =0.1;

        ctx.stroke();
    });
};

// NUQTALAR (dots) CHIZISH
const drawCircle = (ctx, p) => {
    ctx.beginPath();
    ctx.arc(p.x, p.y, 3, 0, 2 * Math.PI, false);
    ctx.fillStyle = "rgba(27, 107, 80, 0.2)"; // #1b6b50 rangida dots
    ctx.fill();
};

// NUQTA ORASIDAGI MASOFANI HISOBLASH
const getDistance = (p1, p2) => {
    return Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2);
};

// NUQTALAR HARAKATINI BAJARISH
const shiftPoint = (p) => {
    gsap.to(p, {
        duration: 1 + Math.random(),
        x: p.originX - 50 + Math.random() * 100,
        y: p.originY - 50 + Math.random() * 100,
        ease: "power2.inOut",
        onComplete: () => shiftPoint(p),
    });
};

export default CanvasAnimation;
