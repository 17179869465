import React from 'react';
import Layout from "@/locales/en/layout/Layout";
import CustomTitle from "@/components/custom_title";
import {useTranslation} from "react-i18next";
import ImageGallery from "react-image-gallery";
// import stylesheet if you're not already using CSS @import
import "react-image-gallery/styles/css/image-gallery.css";

function GreenUniversitet(props) {
    const {t} = useTranslation();
    document.title = "Yashil universitet";
    const images = [
        {
            original: "https://picsum.photos/id/1018/1000/600/",
            thumbnail: "https://picsum.photos/id/1018/250/150/",
        },
        {
            original: "https://picsum.photos/id/1015/1000/600/",
            thumbnail: "https://picsum.photos/id/1015/250/150/",
        },
        {
            original: "https://picsum.photos/id/1019/1000/600/",
            thumbnail: "https://picsum.photos/id/1019/250/150/",
        },
    ];
    return (
        <Layout>
            <CustomTitle name={"Yashil universitet"} my={true}/>
            <div className="container">
                <div className="row">
                    <div className="col-sm-4">
                        <div className="card" style={{width: "18 rem"}}>
                            <iframe width="100%" height="300" src="https://www.youtube.com/embed/5kwbqip4zYk"
                                    title="TDTUda “Yashil makon” umummilliy loyihasi doirasida ko‘chat ekish aksiyasi o‘tkazildi"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            <div className="card-body">
                                <b>TDTUda “Yashil makon” umummilliy loyihasi doirasida ko‘chat ekish aksiyasi
                                    o‘tkazildi</b>
                                <p className="card-text mt-2">
                                    Islom Karimov nomidagi Toshkent davlat texnika universitetida mamlakatimizda
                                    ekologik vaziyatni yaxshilash, yashil maydonlarni kengaytirishga hissa qo‘shish
                                    maqsadida “Yashil makon” umummilliy loyihasi doirasida ko‘chat ekish aksiyasidan
                                    video lavha!!!
                                </p>
                            </div>
                        </div>
                    </div>
                    {/*<div className="col-sm">*/}
                    {/*    <div className="card" style={{width: "18 rem"}}>*/}
                    {/*        <ImageGallery items={images}/>*/}
                    {/*        <div className="card-body">*/}
                    {/*            <p className="card-text">Some quick example text to build on the card title and make up*/}
                    {/*                the bulk of the card's content.</p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="col-sm">*/}
                    {/*    <div className="card" style={{width: "18 rem"}}>*/}
                    {/*        <ImageGallery items={images}/>*/}
                    {/*        <div className="card-body">*/}
                    {/*            <p className="card-text">Some quick example text to build on the card title and make up*/}
                    {/*                the bulk of the card's content.</p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                </div>
            </div>
        </Layout>
    );
}

export default GreenUniversitet;